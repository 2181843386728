<template>
  <div>
    <section class="card-container">
      <div class="form-wrapper">
        <p class="title">{{ pageTitle }}</p>
        <div class="hint">
          <el-tooltip placement="right">
            <div slot="content">
              <p>當您的使用者點擊追蹤連結後，會於48小時內紀錄用戶資料</p>
              <br>
              <p>1.期間內點擊連結並註冊，將會為會員貼上註冊標籤、活動標籤</p>
              <br>
              <p>2.期間內先點擊連結未註冊，48小時內成為會員，也會歸因於該活動標籤</p>
              <br>
              <p>3.期間內點擊連結，已是會員者將會貼上活動標籤</p>
              <br>
              <p>4.期間內點擊連結，但期間內未成為會員，將不貼標</p>
            </div>
            <div class="flex items-center" style="gap: 12px">
              <p>連結貼標說明</p>
              <span class="material-icons" style="width: 24px">
                help_outlined
              </span>
            </div>
          </el-tooltip>
        </div>
        <BaseElForm ref="formRef" :model="formData" :rules="formRules" label-position="top">
          <BaseElFormItem label="連結名稱" prop="name">
            <BaseElInput v-model="formData.name" clearable placeholder="請輸入" />
          </BaseElFormItem>
          <BaseElFormItem label="原始連結" prop="targetLink">
            <BaseElInput v-model="formData.targetLink" clearable :disabled="!!linkId" placeholder="請輸入欲追蹤連結" />
          </BaseElFormItem>
          <BaseElFormItem label="註冊標籤" prop="registerMTags">
            <BaseElSelect
              ref="registerMTags"
              v-model="formData.registerMTags"
              multiple
              collapse-tags
              placeholder="請選擇標籤名稱"
              @focus="openSelectTag('registerMTags')"
            >
              <BaseElSelectOption
                v-for="tag in formData.registerMTagsOptions"
                :key="tag.id"
                :label="tag.name"
                :value="tag.id"
              />
            </BaseElSelect>
          </BaseElFormItem>
          <BaseElFormItem label="活動標籤" prop="actionMTags">
            <BaseElSelect
              ref="actionMTags"
              v-model="formData.actionMTags"
              multiple
              collapse-tags
              placeholder="請選擇標籤名稱"
              @focus="openSelectTag('actionMTags')"
            >
              <BaseElSelectOption
                v-for="tag in formData.actionMTagsOptions"
                :key="tag.id"
                :label="tag.name"
                :value="tag.id"
              />
            </BaseElSelect>
          </BaseElFormItem>

          <BaseElFormItem label="連結開始時間" prop="start">
            <el-date-picker
              v-model="formData.start"
              editable
              type="date"
              :picker-options="pickerOptions"
              placeholder="請選擇日期"
            />
          </BaseElFormItem>
          <BaseElFormItem label="連結結束時間" prop="end">
            <el-date-picker
              v-model="formData.end"
              editable
              type="date"
              placeholder="請選擇日期"
            />
          </BaseElFormItem>
        </BaseElForm>
      </div>
    </section>

    <MemberTagAdvanceSelect
      enableCreate
      :show.sync="dialog.tagSelect"
      :modelValue="formData[tagsData]"
      :options="customTags"
      :max="tagsData === 'registerMTags' ? 1: 0"
      @update:modelValue="getSelectTag"
    />

    <PageFixedFooter
      :confirmLoading="loading"
      @cancel="$router.go(-1)"
      @confirm="handleConfirm"
    />
  </div>
</template>

<script>
import MemberTagAdvanceSelect from '@/components/Select/MemberTagAdvanceSelect.vue'
import { CreateTrackingLink, FindTrackingLink, UpdateTrackingLink } from '@/api/trackingLink'
import formUtils from '@/utils/form'
import { map, omit, get, filter } from 'lodash'
import { noEmptyRules, urlRules } from '@/validation'
import dayjs from '@/lib/dayjs'
import { useTagStore } from './components/useTagStore'
import { defineComponent, onMounted, computed, reactive, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import { getRefsVue2 } from '@/utils/helper'
import store from '@/store'
import { useShop } from '@/use/shop'
import notifyMessage from '@/config/notifyMessage'

export default defineComponent({
  name: 'EditTrackingLink',
  components: { MemberTagAdvanceSelect },
  setup () {
    const { tags, getAllTags } = useTagStore()
    const route = useRoute()
    const router = useRouter()
    const { shopId } = useShop()
    const formRef = ref(null)

    const formData = reactive({
      name: null,
      targetLink: null,
      start: null,
      end: null,
      registerMTags: [],
      registerMTagsOptions: [],
      actionMTags: [],
      actionMTagsOptions: [],
    })
    const loading = ref(false)
    const tagsData = ref([])
    const dialog = reactive({
      tagSelect: false,
    })

    const customTags = computed(() => filter(tags.value, item => !item.isSystem))

    const formRules = computed(() => {
      const rules = {
        name: [noEmptyRules()],
        targetLink: [noEmptyRules(), urlRules()],
        start: [noEmptyRules()],
        end: [noEmptyRules()],
        registerMTags: [noEmptyRules()],
        actionMTags: [noEmptyRules()],
      }

      return rules
    })

    const pickerOptions = {
      disabledDate (time) {
        return time.getTime() < dayjs(Date.now()).subtract(1, 'd')
      },
    }

    const pageTitle = computed(() => {
      return linkId.value ? '編輯連結' : '新增連結'
    })

    // ?? 當前追蹤連結 ID
    const linkId = computed(() => {
      return get(route, 'params.id')
    })

    // ?? 提交資料
    const submitPayload = computed(() => {
      const data = {
        shopId: shopId.value,
        id: linkId.value,
        ...omit(formData, ['registerMTagsOptions', 'actionMTagsOptions']),
      }

      if (formData.redirectUrl) {
        data.enableRedirect = true
      } else {
        data.enableRedirect = false
      }

      return data
    })

    const openSelectTag = (type) => {
      const refTarget = getRefsVue2(formRef.value, type)
      if (refTarget) refTarget.blur()
      tagsData.value = type
      dialog.tagSelect = true
    }

    // => 確認送出
    const handleConfirm = async () => {
      if (loading.value) return
      loading.value = true
      const isEdit = linkId.value
      const apiMethod = isEdit ? UpdateTrackingLink : CreateTrackingLink

      if (!(await formUtils.checkForm(formRef.value))) {
        loading.value = false
        return
      }
      if (!checkStartDate()) {
        loading.value = false
        return
      }

      const [, err] = await apiMethod(submitPayload.value)
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success(isEdit ? notifyMessage.updateSuccess : notifyMessage.createSuccess)
      router.push({ name: 'TrackingLinkManagement' })
    }

    const findTrackingLink = async () => {
      const [res, err] = await FindTrackingLink({
        shopId: store.getters.shop,
        id: route.params.id,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      syncFormData(res)
    }

    const syncFormData = (res) => {
      formData.name = res.name
      formData.targetLink = res.targetLink
      formData.start = res.start
      formData.end = res.end
      formData.registerMTags = map(res.RegisterMTags, 'id')
      formData.registerMTagsOptions = res.RegisterMTags
      formData.actionMTags = map(res.ActionMTags, 'id')
      formData.actionMTagsOptions = res.ActionMTags
      formData.enableShowPage = res.enableShowPage
    }

    const getSelectTag = (val) => {
      formData[tagsData.value] = [...val]
      formData[`${tagsData.value}Options`] = tags.value.filter(i => val.includes(i.id))
    }

    const checkStartDate = () => {
      const start = formData.start
      const end = formData.end
      if (dayjs(start).isAfter(dayjs(end))) {
        window.$message.error('結束時間不可早於開始時間')
        return false
      }
      return true
    }

    onMounted(() => {
      getAllTags()
      if (linkId.value) findTrackingLink()
    })

    return {
      formData,
      formRules,
      tags,
      customTags,
      loading,
      tagsData,
      dialog,
      pickerOptions,
      pageTitle,
      linkId,
      openSelectTag,
      handleConfirm,
      getSelectTag,
      formRef,
    }
  },
})
</script>

<style lang="scss" scoped>
.title {
  @apply text-primary-100 font-bold text-lg leading-[21.6px] ;
}
.form-wrapper {
  @apply p-[20px] pl-[29px];
}
.hint {
  @apply inline-flex py-[22px];
}
</style>
